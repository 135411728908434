var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isEdit
        ? _c("span", [_vm._v(_vm._s(_vm.labelName))])
        : _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                "allow-create": "",
                clearable: "",
                size: _vm.size,
                disabled: _vm.disabled,
                value: _vm.value,
              },
              on: { change: _vm.handleChange },
            },
            _vm._l(_vm.typeDatas, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  value: item[_vm.defaultProps.value],
                  label: item[_vm.defaultProps.label],
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }